import { ref } from 'vue';
import axios from 'axios';

const getMotd = async () => {
  const motd = ref('');
  const error = ref(null);
  const url = '/api/motd';

  let res;
  try {
    res = await axios.get(url);
    motd.value = await res.data.msg;
  } catch (err) {
    error.value = err.message;
  }

  return { motd, error };
};

export default getMotd;
