<template>
  <a :href="href" class="p-1 text-center border border-t-green">
    <p class="text-lg">
      {{ name }}
    </p>
  </a>
</template>

<script>
export default {
  props: {
    name: String,
    href: String,
  },
  components: {},
  data() {
    return {};
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>
