<template>
  <div class="">
    <div
      class="selection:bg-t-green-light selection:text-t-black drop-shadow-t-green-emit shadow-t-green-light text-t-green"
    >
      <router-view />
    </div>
  </div>
</template>

<style>
@import url('@/fonts.css');

body::-webkit-scrollbar {
  display: none;
}

#app {
  @apply h-screen bg-t-black bg-scroll overflow-auto after:absolute;
  font-family: DungGeunMo, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#app::after {
  @apply mix-blend-overlay bg-t-crt h-screen top-0 left-0 w-full pointer-events-none;
}

.t-green-border {
  @apply border border-t-green;
}
</style>
