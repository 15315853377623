<template>
  <div class="flex flex-col p-4 min-w-full min-h-screen md:flex-row-reverse">
    <!-- Main -->
    <div class="flex flex-col p-4 border grow border-t-green">
      <!-- Header -->
      <div class="flex flex-col md:flex-row md:flex-nowrap">
        <img
          src="@/assets/durumi_terminal_title.png"
          class="object-contain object-top p-4 m-auto w-80 md:w-96"
        />
        <div class="flex flex-col p-2 mb-4 border grow border-t-green">
          <!-- eslint-disable-next-line vue/no-parsing-error -->
          <p class="text-left" v-html="motd"></p>
        </div>
      </div>

      <!-- Socials -->
      <div
        class="flex flex-wrap gap-2 justify-around mb-4"
        v-if="socialEnabled"
      >
        <ProfileCard
          class="flex-1"
          v-for="item in socialList"
          :key="item.name"
          :name="item.name"
          :href="item.href"
        />
      </div>

      <!-- Contents -->
      <div class="border grow border-t-green">
        <h2 class="text-3xl italic">CONTENTS</h2>
      </div>
    </div>
    <!-- Bar -->
    <div
      class="p-4 h-44 border-b md:h-auto md:w-44 md:shrink border-x border-t-green md:border-y md:border-l md:border-r-0"
    >
      <h2 class="text-3xl italic">BAR</h2>
      <div class="flex flex-row gap-2 justify-around md:flex-col md:mb-2">
        <Switch
          v-model="socialEnabled"
          class="p-1 border border-t-green"
          :class="
            socialEnabled
              ? 'bg-t-green-light text-t-black'
              : 'bg-transparent text-t-green'
          "
        >
          <p class="text-lg">Socials</p>
        </Switch>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Switch } from '@headlessui/vue';
import { onMounted, ref } from 'vue';
import ProfileCard from '@/components/ProfileCard.vue';
import getMotd from '@/composables/getMotd';

const motd = ref('');
onMounted(async () => {
  const { motd: motdMsg, error } = await getMotd();
  motd.value = motdMsg.value ? motdMsg.value : error.value + ' :(';
});

const socialEnabled = ref(false);
const socialList = ref([
  { name: 'Youtube', href: 'https://www.youtube.com/@durumi_' },
  { name: 'Github', href: 'https://github.com/SPACEGG' },
  { name: 'Soundcloud', href: 'https://soundcloud.com/durumi' },
  { name: 'Lab', href: 'https://lab.durumi.me' },
]);
</script>
